html
{
    min-height: 100vh;
    background-color: #141116!important;
}

body {
    min-height: 100%;
    background: #141116 !important;
}

*
{
    outline: none !important;
    font-size: 15px;
}

.form-control:focus
{
    box-shadow: none!important;
    border-color: #AAB0BA!important;
}

.btn:focus
{
    box-shadow: none!important;
}

.seporator
{
    border: 0; 
    border-top: 1px solid #EFF1F3;
}

.nav-link{
    color: #141116!important;
}


/*  Boxes  */

.mas-box
{
    box-sizing: border-box;
    margin: 0!important;
    padding-left: 10px;
    padding-right: 10px;
}

.b-toast .toast .toast-body {
    white-space: pre-wrap;
}
@media screen and (max-width: 1023px) 
{
    .mas-box
    {
        margin-left: -25px!important;
        margin-right: -25px!important;
    }

}

.mas-box.gray
{
    background-color: #F8F8F8;
}   
.mas-box.yellow
{
    background-color: #FCF8E3
}

.mas-box.padding
{
    padding-top: 25px;
    padding-bottom: 25px;
}

/*  Buttons  */

.mas-button
{
    display: inline-block;
    font-size: 14px;
    padding: 8px 25px;
    color: #fff!important;
    text-decoration: none!important;
    margin-bottom: 1rem;
    border:0;
    background-color: #141116;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 8px;
    text-align: center;
}

.mas-button.big
{
    font-weight: 500;
}

.mas-button.icon-left > svg
{
    margin-right: 5px;
    margin-left: -5px;
}

.mas-button.icon-left > svg
{
    margin-right: 5px;
}

.mas-button.small
{
    padding: 8px 14px; 
}

.mas-button.empty {
    border: 1px solid #141116;
    color: #141116!important;
    background: none;
    padding: 7px 25px;
}

.mas-button.small.empty {
    padding: 7px 14px; 
}

@media screen and (max-width: 1023px) 
{
    .mas-button
    {   
        width: 100%;
        margin-right: 0;
    }
}



/*  Badges  */
.mas-badge
{
    display: inline-block;
    font-size: 12px;
    color: #141116;
    background-color: #F1F1F1;
    padding: 2px 6px 3px 6px;
    border-radius: 4px;
    white-space: nowrap;
}

.mas-badge > *
{
    display: inline-block;
}

.mas-badge-before > *, .mas-badge-after > *
{
    width: auto!important;
    opacity: 0.7;
}

.mas-badge-before > *
{
    padding-right: 5px;
}

.mas-badge-after > *
{
    padding-left: 5px;
}

.mas-done
{
    background-color: #F0F5EB;
}
.mas-await
{
    background-color: #FEF6E9;
}
.mas-fuckup
{
    background-color: #FBEFEE;
}
.mas-process
{
    background-color: #F1FAFC;
}



    /* PAGE RESTYLE */
    /* PAGE RESTYLE Header */

    .mas-header
    {
        position: relative;
    }

    .mas-tools
    {
        display: flex;
        position: absolute;
        top: -57px;
        right: 0;
        font-weight: 500;
        align-items: center;
        font-size: 14px;
    }
    .mas-tools > div{
        display: inline-block;
        padding-left: 40px;
    }
    .mas-tools > div:first-child{
        padding-left: 0px;
    }

    @media screen and (max-width: 1023px) {
        .mas-header
        {
            display: flex;
        }

        .mas-tools
        {
            position: relative;
            top: auto;
            right: auto;
        }
        .mas-tools > div{
            padding-left: 20px;
        }
        
    }


    /* TABLE PAGE RESTYLE */
    /* TABLE PAGE RESTYLE Filter */

    .mas-filter
    {
        background-color: #F8F8F8!important;
        border: none!important;
        border-radius: 0!important;
        margin-bottom: 10px!important;
    }

    .mas-filter .card-body
    {
        padding-bottom: 0;
    }
    .mas-filter .card-footer
    {
        background: none;
        border: none;
        padding-top: 0;
    }

    .mas-filter-btn
    {
        display: none;
        font-size: 14px;
        background-color: #F8F8F8;
        color: #141116;
        line-height: 24px;
        padding: 8px 16px;
        margin-right: 24px;
    }

    .mas-filter-btn-icon
    {
        float: right!important;
        line-height: 24px;
    }

    @media screen and (max-width: 1023px) {
        .mas-filter
        {
            background-color: #FFF!important;
        }
        .mas-additional-filter {
            display: block!important;
        }
        .mas-more-filters
        {
            display: none!important;
        }   
        .mas-filter-btn
        {
            flex-grow: 99;
            display: block;
        }
        .mas-filter .card-footer
        {
            display: flex;
        }
        .mas-filter .card-footer > *:first-child
        {
            /* width: 49%; */
            margin-right: 8px;
        }
    }



    /* TABLE PAGE RESTYLE Table */

    .mas-table-wrapper
    {
        width: 100%;
        overflow-x: scroll;
    }

    @media screen and (max-width: 1023px) {
        .mas-table-wrapper
        {
            width: 100vw;
            margin-left: -25px;
        }
    }

    .white-space-normal {
        white-space: normal !important;
    }

    .mas-table
    {
        font-size: 14px;
        color: #141116!important;
    }

    .mas-table th, .mas-table td
    {
        vertical-align: middle!important;
        white-space: nowrap;
        border-width: 1px!important;
    }

    .mas-table td
    {
        border: 0!important;
    }

    .mas-table > tbody > tr:nth-child(even)
    {
        background-color: #FBFBFB;
        min-width: 100%;
    }


    /* TABLE PAGE RESTYLE TableTools */

    .mas-table-tools
    {
        min-width: 100%;
        overflow-x: scroll;
        display: flex;
    }

    .mas-table-tools-filters
    {
        white-space: nowrap;
        display: inline-block;
        flex-grow: 99;
    }

    .mas-table-tools-filters > div
    {
        display: inline-block;
        line-height: 40px;
        font-size: 18px;
        padding-right: 20px;
        color: #7D7D7D;
    }

    .mas-table-tools-filters > div.active
    {
        text-decoration: underline;
        color: #141116;
    }

    @media screen and (max-width: 1023px) {
        .mas-table-tools-filters > div
        {
            font-size: 16px;
        }
    }




    /* TABLE PAGE RESTYLE Pagination */

    .mas-pagination-wrapper
    {
        text-align: right;
    }

    @media screen and (max-width: 1023px) {
        .mas-pagination-wrapper
        {
            text-align: center;
        }
        .mas-table-tools .mas-pagination
        {
            display: none;
        }
    }

    .mas-pagination .page-item .page-link
    {
        color: #141116!important;
        box-shadow: none!important
    }


    .mas-pagination .page-item .page-link:hover
    {
        background: #F8F8F8;
    }

    .mas-pagination .page-item > *
    {
        border-radius: 0!important;
    }

    .mas-pagination .page-item.active .page-link
    {
        background-color: #141116!important;
        border-color: #141116!important;
        color: #FFF!important;
    }













/*  old code  */
.breadcrumb-item a {
    color: black;
    text-decoration: underline;
}

.breadcrumb-item a:hover {
    color: #6C757D;
    text-decoration: underline;
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

.cursor-pointer {
    color: gray;
    transition: 0.3s all;
    cursor: pointer;
}

.cursor-pointer.badge-secondary {
    color: white;
}

.cursor-pointer:hover {
    color: black;
}




/* chart fixes */
.apexcharts-title-text
    {
        font-weight: 400!important;
    }
.mas-apex-label
{
    background: #141116;
    border-radius: 0;
    padding: 5px 10px;
    color: #fff;
    border: 0;
}